<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol == 'empleado' || rol == 'admin-depto'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Complementos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol  == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-text-field 
                                    outlined 
                                    label="Nombre del complemento" 
                                    v-model="filterNombre"
                                >
                                </v-text-field>
                            </v-col>

                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.descripcion }}</div>
                                </td>
                                <td>
                                    <div>
                                        <div v-for="(cocina, index) in item.cocinas">
                                            {{ cocina.nombre }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="abrirModal('update', item)"
                                                    small
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    depressed
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="eliminar('cocinas',item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="750px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <!-- <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title> -->

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-row class="my-5">
                                        <template v-if="accion == 'add'">

                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Cliente"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Empresa"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>

                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <VuetifyMultipleAutocomplete
                                                    outlined
                                                    :items="cocinas"
                                                    :valorDefecto="modelCocinas"
                                                    rules="required"
                                                    item_text="nombre"
                                                    @dataMultiple="(data)=> modelCocinas = data"
                                                    label="Cocinas"
                                                    ref="multCocinas"
                                                />
                                            </v-col>

                                            <v-row class="pa-0 ma-0">
                                                <v-col cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre complemento" 
                                                        class="pa-0 ma-0" 
                                                        v-model="complemento.nombre" 
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                                    <v-text-field 
                                                        outlined
                                                        label="Descripción" 
                                                        class="pa-0 ma-0" 
                                                        v-model="complemento.descripcion" 
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="2" md="2" class="pt-0 pb-0 ma-0">
                                                    <v-btn color="#1E2245" class="mt-2" elevation="0" dark fab  @click="agregarComplemento()" small>
                                                        <v-icon dark>add</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>

                                            <v-col cols="12" xs="12" sm="12" md="12" class="my-0 py-0">
                                                <table class="tbl-modal">
                                                    <thead class="tbl-header-modal">
                                                        <tr>
                                                            <th class="pl-7 firstth">Nombre complemento</th>
                                                            <th>Descripción</th>
                                                            <th class="lastth text-center">Opciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="datosTabla.length == 0" >
                                                            <td class="emptyTableModal" colspan="11">
                                                                No hay elementos para mostrar
                                                            </td>
                                                        </tr>
                                                        <template v-else >
                                                            <tr :key="index" v-for="(itemComplemento, index) in datosTabla">
                                                                <td class="pl-5">
                                                                    <v-text-field 
                                                                        outlined
                                                                        label="" 
                                                                        class="pa-0 ma-0" 
                                                                        v-model="itemComplemento.nombre" 
                                                                        dense
                                                                        hideDetails
                                                                    >
                                                                    </v-text-field>
                                                                </td>
                                                                <td class="pr-5 py-2">
                                                                    <v-text-field 
                                                                        outlined
                                                                        label="" 
                                                                        class="pa-0 ma-0" 
                                                                        v-model="itemComplemento.descripcion" 
                                                                        dense
                                                                        hideDetails
                                                                    >
                                                                    </v-text-field>
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-btn class="botonHover" icon fab dark small @click="eliminarComplemento(index)" >
                                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </table>
                                            </v-col>
                                        </template>


                                        <template v-if="accion=='update'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Nombre complemento" 
                                                        class="pa-0 ma-0" 
                                                        v-model="complemento.nombre" 
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Descripción" 
                                                        class="pa-0 ma-0" 
                                                        v-model="complemento.descripcion" 
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0 ma-0">
                                                <table class="tbl-modal">
                                                    <thead class="tbl-header-modal">
                                                        <tr>
                                                            <th class="pl-7 firstth">Nombre cocina</th>
                                                            <th>Descripción</th>
                                                            <th class="lastth text-center">Opciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-if="arrayCocinas.length == 0" >
                                                            <td class="emptyTableModal" colspan="11">
                                                                No hay elementos para mostrar
                                                            </td>
                                                        </tr>
                                                        <template  v-else >
                                                            <tr v-for="(cocina, index) in arrayCocinas" :key="cocina.id">
                                                                <td class="pl-5">
                                                                    {{ cocina.nombre }}
                                                                </td>
                                                                <td class="pr-5 py-2">
                                                                    {{ cocina.descripcion }}
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-btn class="botonHover" icon fab dark small @click="eliminar('cocina',complemento, cocina)" >
                                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </table>

                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
/* API's */
import apiComplementos from "@/api/comidas/complementos";
import apiCocinas from "@/api/comidas/cocinas";
import apiClientes from '@/api/clientes';
import apiEmpresas from '@/api/empresas';

/* COMPONENTES */
import Datatable from "@/components/datatable/Datatable.vue";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete2.vue';

//PLUGINS
import Notify from '@/plugins/notify';

export default {
    components: {
        "data-table": Datatable,
        VuetifyDatePicker,
        VuetifyMultipleAutocomplete
    },
    data() {
        return {
            datosLogin  : null,
            rol         : null,
            url         : "comida-complemento/find",
            columns: [
                {
                    label: "Nombre",
                    name: "nombre",
                    filterable: false,
                },
                {
                    label: "Descripción",
                    name: "descripcion",
                    filterable: false,
                },
                {
                    label: "Cocinas",
                    name: "cocinas",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    filterable: false,
                    align: 'center'
                },
            ],
            filters: {
                activo      : true,
                paginate    : true,
                order_by    : 'nombre',
                cliente_id  : null,
                empresa_id  : null,
                sucursal_id : null,
                nombre      : null,
            },
            complemento: {
                id          : null,
                nombre      : null,
                descripcion : null,
                
            },
            
            tituloModal     : '',
            imagen          : '',
            accion          : '',
            isLoadingModal  : false,
            isLoading       : false,
            dialog          : false,
            isSaving        : false,
            cliente_value   : null,
            empresa_value   : null,
            modelCocinas    : [],
            clientes        : [],
            empresas        : [],
            cocinas         : [],
            nombreCocina    : '',

            datosTabla      : [],
            arrayCocinas    : [],

            filterNombre    : null,

        }
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.getEmpresas(val);
            }
        },
        empresa_value: function(val) {
            this.modelCocinas = [];
            if(val != null){
                this.getCocinas(val);
            }
        },
    },
    methods: {
        async abrirModal(accion, data = null){
            await this.resetValues()
            this.accion         = accion;
            this.isLoadingModal = true;

            if(accion == "add"){
                this.tituloModal    = "Agregar complemento";
                this.imagen         = "/static/modal/brigadasCreate.svg";
                if(this.rol == 'root'){
                    this.getClientes();
                }
                else if(this.rol == 'admin'){
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                else if(this.rol == 'admin-empresa'){
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
                else if(this.rol == 'admin-sucursal'){
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                    this.getCocinas();
                }
                else {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
                
            } else {
                this.tituloModal        = "Editar complemento";
                this.imagen             = "/static/modal/brigadasUpdate.svg";
                this.complemento.id             = data.id;
                this.complemento.nombre         = data.nombre;
                this.complemento.descripcion    = data.descripcion;
                this.arrayCocinas               = data.cocinas;

            }
            this.isLoadingModal  = false;
            this.dialog = true;
        },
        guardar(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                
                this.isLoading = true;
                if(this.accion === "add") {

                    let complementos = {
                        cocinas: [],
                        complementos : []
                    }

                    if(this.datosTabla.length == 0){
                        Notify.ErrorToast("Agregar al menos un complemento a la tabla.");
                        this.isSaving = false;
                        return;
                    }
                    else if(this.datosTabla.length > 0){
                        complementos.complementos = this.datosTabla
                    }

                    let arrCocinas = this.cocinas.filter(item => this.modelCocinas.includes(item.id));

                    arrCocinas.forEach(element => {
                        let temporal        = new Object();
                        temporal.id         = element['id'];
                        temporal.nombre     = element['nombre'];
                        complementos.cocinas.push(temporal)
                    });

                    apiComplementos.store(complementos).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se guardó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {

                    apiComplementos.update(this.complemento).then((response) => {
                        Notify.Success("Operación exitosa", "El registro se actualizó satisfactoriamente.");
                        this.$refs.tabla.getData();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        eliminar(tipo = null, datosComplemento = null, datosCocina = null ){

            let pregunta = "";
            let parametros = {
                cocinas : [],
                complemento_id: null,
            }

            if(tipo == 'cocina'){
                pregunta = "¿Estás seguro que deseas eliminar la cocina: " + datosCocina.nombre + "?";
                parametros.cocinas.push(datosCocina.id);
                parametros.complemento_id = datosComplemento.id;
            }
            else if(tipo == 'cocinas'){
                pregunta = "¿Estás seguro que deseas eliminar el complemento: " + datosComplemento.nombre + "?";
                parametros.cocinas = datosComplemento.cocinas.map(item => item.id);
                parametros.complemento_id = datosComplemento.id;
            }


            Notify.Alert(
                "ADVERTENCIA",
                pregunta,
                "Eliminar",
                () => {

                    this.isLoading = true;
                    apiComplementos.delete(parametros).then(response => {
                        Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                        this.cerrarModal();
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        cerrarModal(){
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            this.complemento= {
                id          : null,
                nombre      : null,
                descripcion : null,
                
            };
            this.cocinas        = [];
            this.modelCocinas   = [];
            this.datosTabla     = [];
            this.filterNombre   = null;

            if(this.rol == 'root'){
                this.getClientes();
                this.cliente_value  = null;
                this.empresa_value  = null;
                this.empresas = [];
            }
            else if(this.rol == 'admin'){
                this.cliente_value  = null;
                this.empresa_value = null;
                this.empresas = [];
                
            }
            else if(this.rol == 'admin-empresa'){
                this.cliente_value  = null;
                this.empresa_value = null;
            }
            else {
                
            }

            this.$nextTick(() => {

                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        async loadModalData(){
            await this.resetValues()
            if(this.rol == 'root'){
                await this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else {
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        async setFilters(){
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = null;
            let nombre = this.filterNombre;
            if(this.rol == 'root'){

            }
            else if (this.rol == "admin") {    
                cliente = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            }
            else{
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
            }
            this.filters.cliente_id     = cliente;
            this.filters.empresa_id     = empresa;
            this.filters.sucursal_id    = sucursal;
            this.filters.nombre         = nombre;

            await this.$refs.tabla.getData();
            await this.resetValues();
        },
        getClientes(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre'
            }
            
            apiClientes.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        getEmpresas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value
            }
            apiEmpresas.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        getCocinas(){
            let parametros = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                order_by    : 'nombre',
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value,
                sucursal_id : null,
            }

            if(this.rol == 'admin-sucursal') {
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }

            apiCocinas.find(parametros).then((response) => {
                this.cocinas = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las cocinas.");
            });
        },
        listar(){
            if(this.rol == 'root'){
                this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if(this.rol == 'admin-sucursal'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
            }
            else {
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },
        agregarComplemento(){
            if(this.complemento.nombre == null){
                Notify.ErrorToast("Asigna un nombre al complemento.");
                return;
            }

            let itemComplemento = { 
                nombre          : this.complemento.nombre, 
                descripcion     : this.complemento.descripcion 
            }
            this.datosTabla.push(itemComplemento);

            this.complemento.nombre         = null;
            this.complemento.descripcion    = null;
        },
        eliminarComplemento(index){
            this.datosTabla.splice(index,1);
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.datosLogin = this.$session.get('usuario');
        this.rol        = this.datosLogin.rol.name;
        this.listar();
    },
}
</script>
<style scoped>
    th{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }

    .tbl-modal tbody td{
        color: #828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .tbl-modal {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-modal .tbl-header-modal {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .emptyTableModal{
        color: #BDBDBD !important;
        font-family: "Montserrat" !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 15px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
    }
    .subtituloCocinas{
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        color:#1E2245;
    }
    .itemCocina{
        font-family: "Montserrat" !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        color:#1E2245;
    }
</style>